@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

.Calculator {
    font-family: 'Share Tech Mono', monospace;
    width: 300px;
    background-color: #1D3557;
    padding: 20px 20px 50px 20px;
    /*border-radius: 6px;*/
    /*border-bottom-left-radius: 6px;*/
    /*border-bottom-right-radius: 6px;*/
    margin: 0 auto;
    /*box-shadow: 12px 12px 1px 1px #e6394660;*/
}

.Calculator .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    margin-bottom: 20px;
}
.Calculator .Header .Name {
    color: white;
    opacity: 0.4;
}
.Calculator .Header .BatteryContainer {
    display: flex;
    flex-direction: row;
    column-gap: 2px;
}
.Calculator .Header .Battery {
    width: 35px;
    height: 35px;
    background-color: white;
    opacity: 0.2;
    border-radius: 3px;
}

.Calculator .Display {
    height: 50px;
    width: 100%;
    background-color: #A8DADC;
    color: black;
    margin-bottom: 25px;
    padding: 20px 0;
    border-radius: 3px;
    box-shadow: inset 4px 4px 0px 1px #457B9D60;
}
.Calculator .Display .DisplayInput {
    text-align: left;
    padding: 0 20px;
    font-size: 1.2rem;
    overflow: hidden;
    display: flex;
}
.Calculator .Display .DisplayOutput {
    text-align: right;
    font-weight: 500;
    padding: 0 20px;
    font-size: 1.9rem;
    overflow: hidden;
}

.Calculator .Buttons {
    display: flex;
    flex-direction: column;
    /*row-gap: 2px;*/
}

.Calculator .Row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: stretch;
    flex-grow: 1;
    margin: 1px 0;
    /*column-gap: 2px;*/
}

.Calculator button {
    width: 100px;
    height: 50px;
    background-color: #f1faee;
    border: 0;
    font-size: 20px;
    font-family: inherit;
    margin: 0 1px;
    border-radius: 3px;
}
.Calculator button:hover {
    cursor: pointer;
    opacity: 0.85;
}


.Calculator button[columns="3"]{
    width: 310px;
}

.Calculator .Danger {
    background-color: #e63946;
}

.Calculator .Functional {
    background-color: #457B9D;
}

.CalculatorCursor {
    width: 4px;
    height: 20px;
    background-color: #457B9D;
    border-radius: 1px;
    animation: BlinkCursor;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
}

@keyframes BlinkCursor {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0.7;
    }
    50% {
        opacity: 0.7;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0.1;
    }
}


