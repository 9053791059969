@import url('https://fonts.googleapis.com/css2?family=Share+Tech&display=swap');


/*https://coolors.co/palette/000000-2f4550-586f7c-b8dbd9-f4f4f9*/
html .App {
  font-family: 'Share Tech', sans-serif;
  background-color: #F4F4F9;
  color: #282c34;
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: start;
  flex-direction: column;
  /*background: url("./images/exooer_1920x1080.png") no-repeat center center fixed;*/
  /*background: url("./images/d65dyj_1920x1080.png") no-repeat center center fixed;*/
  background: url("./images/nexq1w_1600x900.png") no-repeat center center fixed;
  background-size: cover;
  position: absolute;
  overflow: hidden;
}

html[mode=dark] .App {
  background: #282c34;
  /*color: white;*/
}

.Main {
  display: flex;
  justify-content: start;
  align-items: end; /* Needed for displaying minimized windows on bottom */
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  /*flex-wrap: wrap;*/
}

.Windows {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: end;
  justify-content: flex-start;
}


.App-header {
  /*background-color: #282c34;*/
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
  padding: 10px;
}

.App-header ul.Menu {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}

.App-header ul.Menu li {
  margin-right: 10px;
}

.App-header .Tools {
  display: flex;
  flex-direction: row;
}

.App-link {
  color: #61dafb;
}

