.Radio {
    width: 400px;
    max-width: 100%;
    height: 500px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
    /*min-width: 500px;*/
}
.RadioMenuButtons {
    padding: 10px 0 0 0;
    display: flex;
    column-gap: 5px;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 5px;
}
.RadioMenuButtons button {
    border: 1px solid grey;
    padding: 4px 10px;
    background-color: black;
    color: white;
    font-family: inherit;
    border-radius: 3px;
    cursor: pointer;
}
.RadioMenuButtons button:hover {
    opacity: 0.74;
}

.PlaylistList {
    margin:10px;
    padding: 0;
    background-color: white;
    color: black;
    overflow: auto;
    scrollbar-width: thin;
    display: flex;
    flex: 10 1 auto;
    flex-direction: column;
    min-height: 200px;
}
.PlaylistList li {
    text-align: left;
    cursor: pointer;
    list-style: none;
    padding: 4px 4px;
    display: flex;
    column-gap: 6px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.PlaylistList li:hover {
    background-color: #00000044;
}
.PlaylistList li .name {
    opacity: 1;
    display: flex;
    white-space: nowrap;
}
.PlaylistList li .url {
    opacity: 0.2;
    display: flex;
    white-space: nowrap;
    margin-left: 10px;
}
.PlaylistList li > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.PlaylistQueryContainer {
    padding: 20px 10px 0 10px;
    display: flex;
    justify-content: stretch;
    align-content: center;
}


.PlaylistQueryContainer input {
    width: 100%;
    font-size: 16px;
    padding: 3px;
}

.currentSourceContainer {
    background-color: black;
    padding: 0 10px 10px 10px;
}

.currentSourceName {
    padding: 20px;
    /*margin: 10px;*/
    text-align: left;
    color: white;
    background-color: black;
}

.favoriteButton {
    background-image: url("../../images/3844463-famous-favorite-featured-star-super_110327.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    width: 26px;
    min-width: 26px;
    height: 26px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
}

.favoriteButton.active {
    background-image: url("../../images/feedback_rating_like_bookmark_ui_rate_favourite_favorite_star_icon_210796.png");
}

