.settingsGroup {
    padding: 10px;
    min-width: 300px;
    min-height: 100px;
}

.settingsItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5px;
    color: white;
    padding: 4px 10px;
}
