.window {
  position: absolute;
  top: 100px;
  left: 100px;
  user-select: none;
  border-radius: 4px;
  box-shadow: 8px 8px 1px 1px #e6394640;
  z-index: 200;
  padding-bottom: 4px;
  background-color: #1D3557;
  z-index: 1002;
}

.windowMinimized {
  min-width: 220px;
  border: 1px solid #1D3557;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #e63946;
  border-radius: 4px;
  /*color: white;*/
  user-select: none;
  padding: 2px;
}
.windowMinimized > div {
  margin-right: auto;
}


.windowFullscreen {
  position: absolute;
  top: 0 !important; /* due to leaflet is trying to stay at current position */
  left: 0 !important;
  bottom: 0;
  right: 0;
  width: 100%;
  /*width: 100vw;*/
  /*height: 100vh;*/
}

.windowBody {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  /*position: relative;*/
  /*top:-5px;*/
  /*z-index: 110;*/
}

.windowHeader {
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: #e63946;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  z-index: 120;
  height: 30px;
  user-select: none;
  padding: 0 2px;
}

.activeWindow {
  z-index: 1010;
  border-radius: 3px;
  box-shadow: 8px 8px 1px 1px #ffd16640;
  opacity: 0.8;
}

.activeWindow .windowHeader  {
  background-color: #ffd166;
}

.windowButton {
  width: 24px;
  height: 24px;
  margin: 0 2px;
  background-color: #00000000;
  border: unset;
  /*color: white;*/
  cursor: pointer;
  /*font-weight: 600;*/
  min-width: 24px;
  font-family: inherit;
}
.windowButton:hover {
  background-color: #FFFFFF99;
  border-radius: 2px;
}

.windowButtonMinimize {
  background: url("../../images/window_minimize_icon_198334.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}

.windowButtonMaximize {
  background: url("../../images/072maximize1_99942.png");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
}

.windowButtonLeftSide {
  background: url("../../images/side_left.png");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
}

.windowButtonRightSide {
  background: url("../../images/side_right.png");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
}

/*.windowMinimized .windowButtonMaximize {*/
/*  background-position: right;*/
/*  background-position-x: 96%;*/
/*  width: 100%;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  margin: 0;*/
/*  padding: 16px 8px;*/
/*}*/

.windowButtonClose {
  background: url("../../images/close_111152.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}


.windowCloseWhenFullscreen {
  width: 100px;
  height: 100px;
  background-color: #e6394688;
  position: absolute;
  right: -50px;
  z-index: 2000;
  top: -50px;
  border-radius: 50px;
  cursor: pointer;
}

.windowCloseWhenFullscreen img {
  width: 30px;
  height: 30px;
  position: relative;
  color: black;
  left: -20px;
  top: 55px;
}

.windowCloseWhenFullscreen:hover {
  opacity: 0.8;
}


.moveHeaderHandler {
  height: 24px;
  width: 100%;
  cursor: move;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
}

.ProgramIconContainer {
  position: absolute;
  /*border: 2px solid blue;*/
  top:0;
  left:0;
  display: flex;
  flex-wrap: wrap;
  z-index: 1001;
}

.programIcon {
  width: 100px;
  height: 100px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.programIcon:hover {
  cursor: pointer;
  background-color: #FFFFFF90;
}

.programIconImage {
  width: 50px;
  height: 50px;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}
.programIconImage img {
  width: 50px;
  height: 50px;
}


.programIconName {
  font-size: 15px;
  /*font-weight: 600;*/
  user-select: none;
  background-color: #FFF9;
  border-radius: 2px;
  padding: 4px 5px 2px 5px;
}

.programIcon:hover .programIconName {
  background-color: transparent;
}

.hidden {
  display: none;
}

.rightHalfOfScreenWindow {
  position: absolute;
  top: 0 !important;
  left: 50%  !important;
  bottom: 0;
  right: 0;
  width: 50%;
  /*display: flex;*/
  /*flex-direction: column;*/
}

.leftHalfOfScreenWindow {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  bottom: 0;
  right: 0;
  width: 50%;
  /*display: flex;*/
  /*flex-direction: column;*/
}

@media (max-width:600px) {
  .Main {
    display: flex;
    flex-direction: column-reverse;
  }
  .ProgramIconContainer {
    top: unset;
    left: unset;
    position: relative;
    display: flex;
    /*column-gap: 5px;*/
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
    background-color: black;
    scroll-snap-type: x mandatory;
    user-select: none;
  }
  .Windows {
    background-color: #457B9D;
    width: 100%;
    height: unset;
    flex: 1 10 auto;
  }
  .windowHeader {
    display: none;
  }
  .window {
    position: relative;
    top: unset;
    left: unset;
    width: 100%;
    height: 100%;
    box-shadow: unset;
    display: none;
  }
  .window.isCurrentWindow {
    display: flex;
  }

  .hiddenOnMobile {
    display: none;
  }

  .programIcon {
    height: 60px;
    width: 80px;
    margin: 8px 0;
    margin-left: 8px;
  }
  .programIconName {
    background-color: unset;
    color: #CCC;
  }
  .programIconImage {
    margin-bottom: 0;
  }
  .programIconImage img {
    width: 30px;
    height: 30px;
  }

  .isCurrentWindowIcon {
    cursor: pointer;
    background-color: #FFFFFF90;
  }
  .isCurrentWindowIcon .programIconName {
    background-color: transparent;
    color: black;
  }


}