.map {
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 400px;
    min-height: 400px;
    /*width: 600px;*/
    /*height: 600px;*/
}

/*.leaflet-container {*/
/*    width: 600px;*/
/*    height: 600px;*/
/*}*/
